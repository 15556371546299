







































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import Chart from "./Chart.vue";
import firebase from "@/plugins/firebase";

interface CourseReg {
  courseId: string;
  courseName: string;
  uid: string;
  fullName: string;
}

interface UserExperience {
  date: Date;
  user: {
    displayName: string;
    email: string;
    uid: string;
  };
  route: {
    params: unknown;
    path: string;
    title: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
}

@Component({
  metaInfo: {
    title: "Yönetim Paneli"
  },
  components: {
    Chart
  }
})
export default class AdminIndex extends Vue {
  indicators = [
    {
      label: "Kullanıcı Limiti",
      value: 0
    },
    {
      label: "Aktif Kullanıcı",
      value: 0
    },
    {
      label: "Pasif Kullanıcı",
      value: 0
    },
    {
      label: "Eğitim Sayısı",
      value: 0
    }
  ];

  chartData = {
    labels: ["Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim"],
    datasets: [
      {
        label: "Günlük Oturum Sayısı",
        backgroundColor: this.$vuetify.theme.currentTheme.secondary,
        data: [0, 0, 0, 0, 0, 0]
      }
    ]
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };

  completedCregs: CourseReg[] = [];

  userExperiences: UserExperience[] = [];

  get business() {
    return this.$store.getters["auth/business"];
  }

  get businessRegistration() {
    return this.$store.getters["auth/businessRegistration"];
  }

  async getIndicators() {
    try {
      // Kullanıcı sayılarını al
      const breg = await firebase
        .firestore()
        .doc(`/businessRegistrations/${this.businessRegistration.id}`)
        .get();

      if (breg.exists) {
        this.indicators[0].value = breg.data()?.maxUsers; // Kullanıcı Limiti
        this.indicators[1].value = breg.data()?.activeUsers; // Aktif Kullanıcı
        this.indicators[2].value = breg.data()?.passiveUsers; // Pasif Kullanıcı
      }

      // Toplam ders sayısını al
      const courseQ = await firebase
        .firestore()
        .collection(`courses`)
        .where("disabled", "==", false)
        .get();

      this.indicators[3].value = courseQ.size;
    } catch (err) {
      const e = err as firebase.firestore.FirestoreError;
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Göstergeler yüklenemedi."
      });

      console.error(`Hata: Göstergeler yüklenemedi. ${e.message}`);
    }
  }

  async getLastCompletedCourses() {
    try {
      this.completedCregs = [];

      // Tüm kullanıcı ID'lerini al
      const allUids = [] as string[];
      const userInvQ = await firebase
        .firestore()
        .collection(`businessUserInvitations`)
        .where("businessRegId", "==", this.businessRegistration.id)
        .get();

      userInvQ.forEach(inv => {
        allUids.push(inv.data().uid);
      });

      // uid'leri tara. Firebase in sorgusunda max. 10 öğeye izin veriyor
      const limit = 10;
      for (let i = 0; i < allUids.length / limit; i++) {
        const start = i * limit;
        const finish = start + limit;
        const uids = allUids.slice(start, finish);

        // Tamamlanan eğitimleri yükle
        const completedCregQuery = await firebase
          .firestore()
          .collection("businessCourseRegistrations")
          .where("uid", "in", uids)
          .where("progress", "==", 100)
          //.limit(10)
          .get();

        completedCregQuery.forEach(async creg => {
          // Dersi al
          const course = await firebase
            .firestore()
            .doc(`courses/${creg.data().courseId}`)
            .get();

          // Kullanıcıyı al
          const userQ = await firebase
            .firestore()
            .collection("businessUserInvitations")
            .where("businessRegId", "==", this.business.regId)
            .where("uid", "==", creg.data().uid)
            .get();

          const user = userQ.docs[0];

          this.completedCregs.push({
            courseId: creg.data().courseId,
            courseName: course.data()?.name,
            uid: user.id,
            fullName: `${user.data().firstName} ${user.data().lastName}`
          });
        });
      }

      // Kayıtları sırala
      this.userExperiences.sort((a: UserExperience, b: UserExperience) => {
        return a.date.valueOf() - b.date.valueOf();
      });
      this.userExperiences.reverse();
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Tamamlanan eğitimler yüklenemedi."
      });

      console.error(`Hata: Tamamlanan eğitimler yüklenemedi. ${err}`);
    }
  }

  async getLastUserExpriences() {
    try {
      this.userExperiences = [];

      // Tüm kullanıcı ID'lerini al
      const allUids = [] as string[];
      const userInvQ = await firebase
        .firestore()
        .collection(`businessUserInvitations`)
        .where("businessRegId", "==", this.businessRegistration.id)
        .get();

      userInvQ.forEach(inv => {
        if (inv.data().isAccepted) {
          allUids.push(inv.data().uid);
        }
      });

      // uid'leri tara. Firebase in sorgusunda max. 10 öğeye izin veriyor
      const limit = 10;
      for (let i = 0; i < allUids.length / limit; i++) {
        const start = i * limit;
        const finish = start + limit;
        const uids = allUids.slice(start, finish);

        // Logları yükle
        const logQuery = await firebase
          .firestore()
          .collection("lessonLogs")
          .where("user.uid", "in", uids)
          .orderBy("date", "desc")
          .get();

        logQuery.forEach(async log => {
          this.userExperiences.push({
            date: log.data().date.toDate(),
            user: log.data().user,
            route: log.data().route,
            item: log.data().item
          });
        });
      }

      // Kayıtları sırala
      this.userExperiences.sort((a: UserExperience, b: UserExperience) => {
        return a.date.valueOf() - b.date.valueOf();
      });
      this.userExperiences.reverse();
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Öğrenme deneyimleri yüklenemedi."
      });

      console.error(`Hata: Öğrenme deneyimleri yüklenemedi. ${err}`);
    }
  }

  @Watch("businessRegistration", { immediate: true })
  async onBusinessRegChanged() {
    await this.getIndicators();
    await this.getLastCompletedCourses();
    await this.getLastUserExpriences();
  }

  async mounted() {
    await this.getIndicators();
    await this.getLastCompletedCourses();
    await this.getLastUserExpriences();
  }
}
